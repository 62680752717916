import * as React from 'react';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass';
import { WebshopNewLayout } from '../../../layouts/WebshopNew.layout';
import axios from 'axios';
import { navigateTo } from '../../../components/functional/Navigate';
// import { successfullPurchase } from '../../../shared-state/main';
import { logError } from '../services/logErrors';
import { weAreRunningInBrowser } from '../../../utils/weAreRunningInBrowser';
import StoreBadgeIcons from '../../../components/layout/StoreBadgeIcons/StoreBadgeIcons';

const PurchaseFinalAllSecurePage = () => {
  // const [bundleDetails, setBundleDetails] = useRecoilState(purchasePackage);
  // const [vozziApiCallbackUrl] = useRecoilState(allSecureData);
  const [user, setUser] = React.useState({
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    userMobileNumber: '',
    lat: null,
    lng: null,
    terms: false,
  });
  const [paymentStatus, setPaymentStatus] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [img, setImg] = React.useState<string | null>(null);

  const { t, i18n } = useTranslation();

  const LANG = i18n.language;

  const metaOgImg = `/img/meta-img/road-assistance-meta-og-2-0-new.jpeg`;

  const meta = {
    title: t('meta_RoadsideAssistanceTitle'),
    description: t('meta_RoadsideAssistanceDescription'),
  };

  const og = {
    image: metaOgImg,
  };

  React.useEffect(() => {
    // let bundleObject = bundle;
    const bundleId = weAreRunningInBrowser() ? JSON.parse(localStorage.getItem('allsecureData')).bundleId : null;
    const user: any = weAreRunningInBrowser() ? JSON.parse(localStorage.getItem('allsecureData')).user : null;
    const vozziApiCallbackUrl = weAreRunningInBrowser() ? localStorage.getItem('vozziApiCallbackUrl') : null;
    const pibNumber: string = weAreRunningInBrowser() ? JSON.parse(localStorage.getItem('pibNumber')) : null;
    const snnpNumber: string = weAreRunningInBrowser() ? JSON.parse(localStorage.getItem('snnpNumber')) : null;
    const snnpCardLevel: any = weAreRunningInBrowser() ? localStorage.getItem('snnpCardLevel') : null;
    const snnpCardStatus: any = weAreRunningInBrowser() ? localStorage.getItem('snnpCardStatus') : null;
    const data = user;

    if (!vozziApiCallbackUrl) {
      navigateTo(t('link_home'));
    }

    setUser({
      ...user,
      userFirstName: null,
      userLastName: null,
      userEmail: data.userEmail,
      userMobileNumber: data.userMobileNumber,
    });

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const resourcePath = params.get('resourcePath');

    data.resourcePath = resourcePath;
    data.bundleId = bundleId;
    data.snnpCardNumber = snnpNumber;
    data.snnpCardLevel = snnpCardLevel;
    data.snnpCardStatus = snnpCardStatus;

    let pibNumberParam = '';
    if (pibNumber !== null) {
      pibNumberParam = `&pibNumber=${pibNumber}`;
    }

    // we call api to create client and client bundle
    axios
      .post(`${vozziApiCallbackUrl}` + pibNumberParam, {
        ...data,
        userMobileNumer: `%2B${data.userMobileNumber}`,
      })
      .then((response) => {
        localStorage.removeItem('snnpNumber');
        localStorage.removeItem('snnpCardLevel');
        localStorage.removeItem('snnpCardStatus');
        localStorage.removeItem('allsecureData');
        localStorage.removeItem('bundleId');
        localStorage.removeItem('bundleKeyName');
        localStorage.removeItem('vozziApiCallbackUrl');
        localStorage.removeItem('pibNumber');
        if (!response.data.data.success) {
          setImg('/img/failure-payment.png');
        }
        setErrorMessage(response.data.data.message);
        setPaymentStatus(response.data.data.success);
      })
      .catch((error: any) => {
        if (error) {
          logError(`PurchaseFinalAllSecurePage. Error message: ${error.message || 'no err msg'}`, null, false, {
            user,
            bundleId,
          });
          setImg('/img/failure-payment.png');
          setErrorMessage('Transakcija nije uspela. Molimo pokušajte ponovo.');
          localStorage.removeItem('snnpNumber');
          localStorage.removeItem('snnpCardLevel');
          localStorage.removeItem('snnpCardStatus');
          localStorage.removeItem('allsecureData');
          localStorage.removeItem('bundleId');
          localStorage.removeItem('bundleKeyName');
          localStorage.removeItem('vozziApiCallbackUrl');
          localStorage.removeItem('pibNumber');
        }
      });

    return () => {
      console.log('unset');
    };
  }, []);

  if (!user) {
    navigateTo(t('link_home'));
  }

  return (
    <WebshopNewLayout meta={meta} og={og}>
      <Container style={{ padding: '60px 0', backgroundColor: 'white' }}>
        <div style={{}}>
          {paymentStatus ? (
            <>
              <img style={{ width: '200px' }} src="/img/success-payment.png" />
              <Text style={{ margin: '50px 0' }}>
                {t('successfullPurchaseEmail')} {user.userEmail}.
                <br />
                <br /> {t('successfullPurchasePhoneNumberPartOne')} {user.userMobileNumber}
                {t('successfullPurchasePhoneNumberPartTwo')}
                <br /> {t('successfullPurchaseAppText')}
                <br />
                <br /> {t('successfullPurchaseThankYouText')}
              </Text>
              <StoreBadgeIcons />
            </>
          ) : (
            <>
              {img !== null ? (
                <>
                  <img style={{ width: '200px' }} src={img} />
                  <Text style={{ margin: '50px 0' }}>{errorMessage}</Text>
                  <StoreBadgeIcons />
                </>
              ) : null}
            </>
          )}
        </div>
      </Container>
    </WebshopNewLayout>
  );
};

export default PurchaseFinalAllSecurePage;
